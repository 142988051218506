<template>
  <div>
    <svg
      width="298"
      height="408"
      viewBox="0 0 298 408"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <line
          x1="203.24"
          y1="12.7244"
          x2="207.5"
          y2="1.01986"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="229.864"
          y1="12.1777"
          x2="236.092"
          y2="1.39079"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="256.656"
          y1="11.6453"
          x2="264.662"
          y2="2.10368"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="283.65"
          y1="10.7437"
          x2="293.192"
          y2="2.73735"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="17.7088"
          y1="12.2758"
          x2="8.16716"
          y2="4.26944"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="44.4605"
          y1="12.9309"
          x2="36.4542"
          y2="3.38926"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="71.0519"
          y1="13.1777"
          x2="64.8241"
          y2="2.39079"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="97.5291"
          y1="13.4084"
          x2="93.269"
          y2="1.7039"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="123.937"
          y1="13.6297"
          x2="121.774"
          y2="1.36324"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="150.324"
          y1="13.4561"
          x2="150.324"
          y2="1.00036"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="176.742"
          y1="13.2824"
          x2="178.905"
          y2="1.01594"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="175.217"
          y1="34.5222"
          x2="179.477"
          y2="22.8177"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="201.837"
          y1="33.9746"
          x2="208.065"
          y2="23.1877"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="228.628"
          y1="33.4431"
          x2="236.635"
          y2="23.9015"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="255.627"
          y1="32.5416"
          x2="265.168"
          y2="24.5352"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="282.859"
          y1="31.2736"
          x2="293.646"
          y2="25.0458"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="16.4371"
          y1="34.7287"
          x2="8.43071"
          y2="25.1871"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="43.0285"
          y1="34.9746"
          x2="36.8007"
          y2="24.1877"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="69.5017"
          y1="35.2063"
          x2="65.2416"
          y2="23.5018"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="95.9097"
          y1="35.4266"
          x2="93.7468"
          y2="23.1601"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="122.297"
          y1="35.2529"
          x2="122.297"
          y2="22.7972"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="148.718"
          y1="35.0793"
          x2="150.881"
          y2="22.8128"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="147.189"
          y1="56.3201"
          x2="151.449"
          y2="44.6156"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="173.814"
          y1="55.7725"
          x2="180.042"
          y2="44.9855"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="200.605"
          y1="55.24"
          x2="208.611"
          y2="45.6984"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="227.603"
          y1="54.3384"
          x2="237.145"
          y2="46.3321"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="254.836"
          y1="53.0705"
          x2="265.623"
          y2="46.8427"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="282.33"
          y1="52.2185"
          x2="294.034"
          y2="47.9584"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="15.0012"
          y1="56.7725"
          x2="8.77331"
          y2="45.9855"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="41.4783"
          y1="57.0041"
          x2="37.2182"
          y2="45.2996"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="67.8863"
          y1="57.2244"
          x2="65.7234"
          y2="44.958"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="94.2734"
          y1="57.0508"
          x2="94.2734"
          y2="44.5951"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="120.691"
          y1="56.8771"
          x2="122.854"
          y2="44.6107"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="119.166"
          y1="78.117"
          x2="123.426"
          y2="66.4124"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="145.786"
          y1="77.5703"
          x2="152.014"
          y2="66.7834"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="172.578"
          y1="77.0379"
          x2="180.584"
          y2="67.4963"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="199.576"
          y1="76.1363"
          x2="209.118"
          y2="68.1299"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="226.812"
          y1="74.8683"
          x2="237.599"
          y2="68.6405"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="254.306"
          y1="74.0164"
          x2="266.011"
          y2="69.7563"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="282.08"
          y1="72.8033"
          x2="294.347"
          y2="70.6404"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="13.4509"
          y1="78.801"
          x2="9.19083"
          y2="67.0965"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="39.8589"
          y1="79.0223"
          x2="37.696"
          y2="66.7558"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="66.2461"
          y1="78.8486"
          x2="66.2461"
          y2="66.3929"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="92.6675"
          y1="78.675"
          x2="94.8304"
          y2="66.4085"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="91.1384"
          y1="99.9148"
          x2="95.3985"
          y2="88.2103"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="117.763"
          y1="99.3672"
          x2="123.991"
          y2="88.5802"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="144.554"
          y1="98.8357"
          x2="152.561"
          y2="89.2941"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="171.553"
          y1="97.9332"
          x2="181.094"
          y2="89.9268"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="198.785"
          y1="96.6662"
          x2="209.572"
          y2="90.4384"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="226.279"
          y1="95.8132"
          x2="237.984"
          y2="91.5531"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="254.053"
          y1="94.6011"
          x2="266.319"
          y2="92.4382"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="11.8355"
          y1="100.819"
          x2="9.6726"
          y2="88.5527"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="38.2227"
          y1="100.646"
          x2="38.2227"
          y2="88.1898"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="64.6402"
          y1="100.472"
          x2="66.8031"
          y2="88.2054"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="297.68"
          y1="95.418"
          x2="285.224"
          y2="95.418"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="63.115"
          y1="121.712"
          x2="67.3751"
          y2="110.007"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="89.7355"
          y1="121.165"
          x2="95.9634"
          y2="110.378"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="116.527"
          y1="120.633"
          x2="124.533"
          y2="111.091"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="143.525"
          y1="119.731"
          x2="153.067"
          y2="111.725"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="170.762"
          y1="118.463"
          x2="181.549"
          y2="112.235"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="198.256"
          y1="117.611"
          x2="209.96"
          y2="113.351"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="226.029"
          y1="116.398"
          x2="238.296"
          y2="114.235"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="10.1953"
          y1="122.443"
          x2="10.1953"
          y2="109.988"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="36.6168"
          y1="122.27"
          x2="38.7797"
          y2="110.003"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="269.652"
          y1="117.216"
          x2="257.197"
          y2="117.216"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="297.365"
          y1="118.368"
          x2="285.099"
          y2="116.205"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="35.0877"
          y1="143.51"
          x2="39.3478"
          y2="131.805"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="61.7121"
          y1="142.962"
          x2="67.9399"
          y2="132.175"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="88.5035"
          y1="142.43"
          x2="96.5099"
          y2="132.889"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="115.502"
          y1="141.529"
          x2="125.043"
          y2="133.523"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="142.734"
          y1="140.261"
          x2="153.521"
          y2="134.033"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="170.228"
          y1="139.409"
          x2="181.933"
          y2="135.149"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="198.002"
          y1="138.196"
          x2="210.269"
          y2="136.033"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="8.58941"
          y1="144.068"
          x2="10.7523"
          y2="131.801"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="241.629"
          y1="139.013"
          x2="229.173"
          y2="139.013"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="269.338"
          y1="140.165"
          x2="257.072"
          y2="138.003"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="296.775"
          y1="141.288"
          x2="285.071"
          y2="137.028"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="7.06421"
          y1="165.307"
          x2="11.3243"
          y2="153.603"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="33.6848"
          y1="164.76"
          x2="39.9126"
          y2="153.973"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="60.4761"
          y1="164.227"
          x2="68.4825"
          y2="154.686"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="87.4744"
          y1="163.326"
          x2="97.016"
          y2="155.319"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="114.711"
          y1="162.058"
          x2="125.498"
          y2="155.83"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="142.205"
          y1="161.206"
          x2="153.909"
          y2="156.946"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="169.979"
          y1="159.994"
          x2="182.245"
          y2="157.831"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="295.93"
          y1="163.79"
          x2="285.143"
          y2="157.562"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="213.605"
          y1="160.811"
          x2="201.15"
          y2="160.811"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="241.315"
          y1="161.963"
          x2="229.048"
          y2="159.8"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="268.748"
          y1="163.085"
          x2="257.043"
          y2="158.825"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="5.66132"
          y1="186.558"
          x2="11.8892"
          y2="175.771"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="32.4527"
          y1="186.025"
          x2="40.4591"
          y2="176.484"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="59.451"
          y1="185.124"
          x2="68.9926"
          y2="177.117"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="86.6836"
          y1="183.856"
          x2="97.4705"
          y2="177.628"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="114.178"
          y1="183.004"
          x2="125.882"
          y2="178.744"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="141.951"
          y1="181.791"
          x2="154.218"
          y2="179.628"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="267.902"
          y1="185.588"
          x2="257.115"
          y2="179.36"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="294.853"
          y1="186.656"
          x2="285.312"
          y2="178.649"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="185.578"
          y1="182.607"
          x2="173.122"
          y2="182.607"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="213.287"
          y1="183.76"
          x2="201.021"
          y2="181.597"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="240.724"
          y1="184.883"
          x2="229.02"
          y2="180.623"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="4.42536"
          y1="207.823"
          x2="12.4317"
          y2="198.281"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="31.4236"
          y1="206.92"
          x2="40.9652"
          y2="198.914"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="58.6602"
          y1="205.654"
          x2="69.4471"
          y2="199.426"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="86.1541"
          y1="204.802"
          x2="97.8586"
          y2="200.541"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="113.928"
          y1="203.588"
          x2="126.194"
          y2="201.426"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="239.879"
          y1="207.386"
          x2="229.092"
          y2="201.158"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="266.826"
          y1="208.453"
          x2="257.284"
          y2="200.446"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="293.578"
          y1="209.109"
          x2="285.571"
          y2="199.567"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="157.555"
          y1="204.405"
          x2="145.099"
          y2="204.405"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="185.264"
          y1="205.558"
          x2="172.997"
          y2="203.395"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="212.701"
          y1="206.681"
          x2="200.996"
          y2="202.421"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="3.40018"
          y1="228.718"
          x2="12.9418"
          y2="220.712"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="30.6328"
          y1="227.45"
          x2="41.4198"
          y2="221.223"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="58.1267"
          y1="226.598"
          x2="69.8313"
          y2="222.338"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="85.9006"
          y1="225.385"
          x2="98.167"
          y2="223.222"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="211.852"
          y1="229.182"
          x2="201.065"
          y2="222.955"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="238.803"
          y1="230.25"
          x2="229.261"
          y2="222.244"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="265.55"
          y1="230.905"
          x2="257.544"
          y2="221.364"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="292.146"
          y1="231.152"
          x2="285.918"
          y2="220.365"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="129.527"
          y1="226.203"
          x2="117.072"
          y2="226.203"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="157.237"
          y1="227.355"
          x2="144.97"
          y2="225.192"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="184.674"
          y1="228.478"
          x2="172.969"
          y2="224.218"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="2.60938"
          y1="249.248"
          x2="13.3963"
          y2="243.02"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="30.1033"
          y1="248.396"
          x2="41.8078"
          y2="244.136"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="57.8771"
          y1="247.183"
          x2="70.1436"
          y2="245.02"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="183.828"
          y1="250.98"
          x2="173.041"
          y2="244.752"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="210.775"
          y1="252.048"
          x2="201.234"
          y2="244.042"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="237.527"
          y1="252.703"
          x2="229.521"
          y2="243.162"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="264.118"
          y1="252.949"
          x2="257.891"
          y2="242.162"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="290.595"
          y1="253.181"
          x2="286.335"
          y2="241.476"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="101.504"
          y1="248"
          x2="89.0482"
          y2="248"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="129.213"
          y1="249.153"
          x2="116.947"
          y2="246.99"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="156.65"
          y1="250.276"
          x2="144.946"
          y2="246.016"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="2.07595"
          y1="270.193"
          x2="13.7805"
          y2="265.933"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="29.8498"
          y1="268.981"
          x2="42.1163"
          y2="266.818"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="155.801"
          y1="272.778"
          x2="145.014"
          y2="266.55"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="182.752"
          y1="273.845"
          x2="173.21"
          y2="265.839"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="209.503"
          y1="274.5"
          x2="201.497"
          y2="264.959"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="236.095"
          y1="274.747"
          x2="229.867"
          y2="263.96"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="262.568"
          y1="274.979"
          x2="258.308"
          y2="263.274"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="288.976"
          y1="275.199"
          x2="286.813"
          y2="262.933"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="73.4766"
          y1="269.798"
          x2="61.0209"
          y2="269.798"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="101.186"
          y1="270.951"
          x2="88.9193"
          y2="268.788"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="128.623"
          y1="272.073"
          x2="116.918"
          y2="267.812"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="1.82635"
          y1="290.778"
          x2="14.0928"
          y2="288.615"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="127.777"
          y1="294.575"
          x2="116.99"
          y2="288.347"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="154.724"
          y1="295.643"
          x2="145.183"
          y2="287.637"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="181.476"
          y1="296.298"
          x2="173.47"
          y2="286.756"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="208.068"
          y1="296.545"
          x2="201.84"
          y2="285.758"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="234.545"
          y1="296.776"
          x2="230.285"
          y2="285.071"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="260.953"
          y1="296.997"
          x2="258.79"
          y2="284.73"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="287.34"
          y1="296.823"
          x2="287.34"
          y2="284.368"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="45.4531"
          y1="291.595"
          x2="32.9974"
          y2="291.595"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="73.1623"
          y1="292.748"
          x2="60.8958"
          y2="290.585"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="100.599"
          y1="293.87"
          x2="88.8949"
          y2="289.61"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="99.75"
          y1="316.373"
          x2="88.9631"
          y2="310.145"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="126.701"
          y1="317.44"
          x2="117.159"
          y2="309.434"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="153.453"
          y1="318.096"
          x2="145.446"
          y2="308.554"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="180.044"
          y1="318.342"
          x2="173.816"
          y2="307.555"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="206.517"
          y1="318.573"
          x2="202.257"
          y2="306.869"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="232.925"
          y1="318.794"
          x2="230.762"
          y2="306.527"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="259.312"
          y1="318.62"
          x2="259.312"
          y2="306.164"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="285.734"
          y1="318.446"
          x2="287.897"
          y2="306.18"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="17.4258"
          y1="313.393"
          x2="4.97009"
          y2="313.393"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="45.1349"
          y1="314.545"
          x2="32.8685"
          y2="312.382"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="72.572"
          y1="315.668"
          x2="60.8675"
          y2="311.408"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="284.205"
          y1="339.686"
          x2="288.465"
          y2="327.982"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="71.7266"
          y1="338.17"
          x2="60.9396"
          y2="331.942"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="98.6736"
          y1="339.238"
          x2="89.132"
          y2="331.231"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="125.425"
          y1="339.893"
          x2="117.419"
          y2="330.351"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="152.017"
          y1="340.14"
          x2="145.789"
          y2="329.353"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="178.494"
          y1="340.37"
          x2="174.234"
          y2="328.666"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="204.902"
          y1="340.592"
          x2="202.739"
          y2="328.325"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="231.289"
          y1="340.418"
          x2="231.289"
          y2="327.962"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="257.707"
          y1="340.244"
          x2="259.87"
          y2="327.978"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="17.1115"
          y1="336.342"
          x2="4.84504"
          y2="334.179"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="44.5486"
          y1="337.465"
          x2="32.8441"
          y2="333.205"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="256.181"
          y1="361.484"
          x2="260.441"
          y2="349.78"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="282.802"
          y1="360.937"
          x2="289.03"
          y2="350.15"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="43.6992"
          y1="359.968"
          x2="32.9123"
          y2="353.74"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="70.6502"
          y1="361.036"
          x2="61.1086"
          y2="353.029"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="97.4019"
          y1="361.691"
          x2="89.3956"
          y2="352.149"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="123.993"
          y1="361.937"
          x2="117.766"
          y2="351.15"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="150.467"
          y1="362.168"
          x2="146.206"
          y2="350.464"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="176.875"
          y1="362.389"
          x2="174.712"
          y2="350.123"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="203.262"
          y1="362.216"
          x2="203.262"
          y2="349.76"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="229.683"
          y1="362.042"
          x2="231.846"
          y2="349.776"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="16.5213"
          y1="359.263"
          x2="4.81674"
          y2="355.003"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="228.154"
          y1="383.282"
          x2="232.414"
          y2="371.577"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="254.779"
          y1="382.734"
          x2="261.006"
          y2="371.947"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="281.57"
          y1="382.202"
          x2="289.576"
          y2="372.66"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="15.6758"
          y1="381.765"
          x2="4.88883"
          y2="375.538"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="42.6228"
          y1="382.832"
          x2="33.0812"
          y2="374.826"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="69.3746"
          y1="383.488"
          x2="61.3682"
          y2="373.946"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="95.966"
          y1="383.734"
          x2="89.7382"
          y2="372.947"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="122.443"
          y1="383.966"
          x2="118.183"
          y2="372.262"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="148.851"
          y1="384.186"
          x2="146.688"
          y2="371.92"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="175.238"
          y1="384.013"
          x2="175.238"
          y2="371.557"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="201.656"
          y1="383.839"
          x2="203.819"
          y2="371.573"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="200.131"
          y1="405.079"
          x2="204.391"
          y2="393.374"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="226.751"
          y1="404.532"
          x2="232.979"
          y2="393.745"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="253.543"
          y1="404"
          x2="261.549"
          y2="394.458"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="280.541"
          y1="403.098"
          x2="290.082"
          y2="395.092"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="14.5994"
          y1="404.63"
          x2="5.05778"
          y2="396.624"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="41.3511"
          y1="405.285"
          x2="33.3448"
          y2="395.744"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="67.9426"
          y1="405.532"
          x2="61.7147"
          y2="394.745"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="94.4158"
          y1="405.763"
          x2="90.1557"
          y2="394.058"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="120.824"
          y1="405.984"
          x2="118.661"
          y2="393.718"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="147.211"
          y1="405.811"
          x2="147.211"
          y2="393.355"
          stroke="#91B8FD"
          stroke-width="2"
        />
        <line
          x1="173.632"
          y1="405.637"
          x2="175.795"
          y2="393.37"
          stroke="#91B8FD"
          stroke-width="2"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "LinePattern",
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
