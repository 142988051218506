import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API,
  authDomain: "ameta-ad6dc.firebaseapp.com",
  projectId: "ameta-ad6dc",
  storageBucket: "ameta-ad6dc.appspot.com",

  appId: "1:126443713852:web:ab48819de37b756dc25a39",
  messagingSenderId: "193154658535",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export default messaging;
