<template>
  <div
    class="flex-grow relative onboardingCarousel flex items-center justify-end"
  >
    <div class="absolute hidden lg:block top-0 right-0"><LinePattern /></div>
    <div>
      <!-- <GroupScreens /> -->
    </div>
    <img
      alt="img"
      src="@/assets/images/dashboard.svg"
      class="z-[99] max-w-[80%]"
    />
  </div>
</template>

<script>
import LinePattern from "@/components/svg/LinePattern.vue";
// import GroupScreens from "@/components/svg/GroupScreens.vue";

export default {
  name: "CarouselSection",
  components: {
    LinePattern,
    // GroupScreens,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
