<template>
  <div
    @click="$emit('clicked')"
    class="cursor-pointer mb-6 flex errorAlert items-center px-3"
  >
    <CancelRed />
    <div class="mx-3 flex-grow flex flex-col">
      <span class="fs-14 fw-500 grey-8">Error!</span>
      <span class="fw-400 fs-12 grey-6">{{ msg }}</span>
      <slot></slot>
    </div>
    <Cancel />
  </div>
</template>

<script>
import CancelRed from "@/components/svg/CancelRed.vue";
import Cancel from "@/components/svg/Cancel.vue";
export default {
  name: "FormErrorAlert",
  props: {
    msg: String,
  },
  components: {
    CancelRed,
    Cancel,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
