<template>
  <div>
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70711 5.79289C6.31658 5.40237 5.68342 5.40237 5.29289 5.79289C4.90237 6.18342 4.90237 6.81658 5.29289 7.20711L8.58579 10.5L5.29289 13.7929C4.90237 14.1834 4.90237 14.8166 5.29289 15.2071C5.68342 15.5976 6.31658 15.5976 6.70711 15.2071L10 11.9142L13.2929 15.2071C13.6834 15.5976 14.3166 15.5976 14.7071 15.2071C15.0976 14.8166 15.0976 14.1834 14.7071 13.7929L11.4142 10.5L14.7071 7.20711C15.0976 6.81658 15.0976 6.18342 14.7071 5.79289C14.3166 5.40237 13.6834 5.40237 13.2929 5.79289L10 9.08579L6.70711 5.79289Z"
        fill="#5C5F62"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Cancel",
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
