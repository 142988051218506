import Home from "../views/Home.vue";
import Login from "@/views/login/Login.vue";

import { Constants } from "@/components/util";

export default [
  {
    path: "/",
    redirect: "/login",
    name: "Home",
    meta: {
      skipAuth: true,
    },
    component: Home,
    children: [
      {
        path: "/login",
        name: "Login",

        component: Login,
        meta: {
          skipAuth: true,
          title: "Login - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Login in Katsu account",
            },
            {
              property: "og:description",
              content: "Login in Katsu account",
            },
          ],
        },
      },
      {
        path: "/reset_password",
        name: "ForgotPassword",

        component: () => import("@/views/forgotPassword/ResetPassword.vue"),
        meta: {
          skipAuth: true,
          title: "forgot password - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Forgot password in Katsu account",
            },
            {
              property: "og:description",
              content: "Forgot password  in Katsu account",
            },
          ],
        },
      },
      {
        path: "/reset-password",
        name: "ResetPassword",

        component: () => import("@/views/forgotPassword/SetNewPassword.vue"),
        meta: {
          skipAuth: true,
          title: "forgot password - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Forgot password in Katsu account",
            },
            {
              property: "og:description",
              content: "Forgot password  in Katsu account",
            },
          ],
        },
      },
    ],
  },

  {
    path: "/main_app",
    redirect: "/dashboard",

    //remember to remove this
    meta: {
      auth: Constants.backOfficeAuth,
    },
    name: "MainApp",
    component: () => import("@/views/MainApp.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Dashboard",

            component: () => import("@/views/main/dashboard/Dashboard.vue"),
            // children: [
            //   {
            //     path: "recent-invoice",
            //     name: "Dashboard Recent Invoic",

            //     component: () =>
            //       import("@/views/main/dashboard/RecentInvoice.vue"),
            //   },
            //   {
            //     path: "recent-payment",
            //     name: "Dashboard Recent Paymen",

            //     component: () =>
            //       import("@/views/main/dashboard/RecentPayments.vue"),
            //   },
            //   {
            //     path: "due-invoices",
            //     name: "Dashboard Due Invoic",

            //     component: () =>
            //       import("@/views/main/dashboard/DueInvoices.vue"),
            //   },
            // ],
            meta: {
              title: "Dashboard - Katsu",
              metaTags: [
                {
                  name: "description",
                  content: "Dashboard in Katsu account",
                },
                {
                  property: "og:description",
                  content: "Dashboard in Katsu account",
                },
              ],
            },
          },
        ],
      },

      {
        path: "/products",
        name: "Products Home",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Products",

            component: () => import("@/views/main/products/ProductsList.vue"),
          },
          {
            path: "add-product",
            name: "Add Products",

            component: () => import("@/views/main/products/AddProduct.vue"),
          },
        ],
        meta: {
          title: "Products - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Products in Katsu account",
            },
            {
              property: "og:description",
              content: "Products in Katsu account",
            },
          ],
        },
      },

      {
        path: "/invoices",
        name: "Invoices Home",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Invoices",
            redirect: "/invoices/list",

            component: () =>
              import("@/views/main/invoices/InvoicesManagement.vue"),
            children: [
              {
                path: "list",
                name: "Invoices List",

                component: () =>
                  import("@/views/main/invoices/InvoicesList.vue"),
              },
              {
                path: "/invoices/invoice-payments",
                name: "Invoices Payments",

                component: () =>
                  import("@/views/main/invoices/InvoicesPayments.vue"),
              },
              {
                path: "/invoices/recurring-invoices",
                name: "Recurring Invoices",

                component: () =>
                  import("@/views/main/invoices/RecurringInvoices.vue"),
              },
            ],
          },
          {
            path: "/invoices/new-invoice",
            name: "New Invoice",

            component: () => import("@/views/main/invoices/NewInvoice.vue"),
          },
          {
            path: "/invoices/edit-invoice/:id",
            name: "Edit Invoice",

            component: () => import("@/views/main/invoices/EditInvoice.vue"),
          },
        ],
        meta: {
          title: "Invoices - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Invoices in Katsu account",
            },
            {
              property: "og:description",
              content: "Invoices in Katsu account",
            },
          ],
        },
      },
      {
        path: "/tenants",
        name: "Tenants",
        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            redirect: "/tenants/verified-tenants",
            name: "Tenants Merchants",
            component: () => import("@/views/main/merchants/Merchants.vue"),
            children: [
              {
                path: "/tenants/verified-tenants",
                name: "Verified Tenants",
                meta: {
                  auth: Constants.backOfficeAuth,
                },
                component: () =>
                  import("@/views/main/merchants/VerifiedCustomers.vue"),
              },
              {
                path: "/tenants/pending-tenants",
                name: "Pending Tenants",
                meta: {
                  auth: Constants.backOfficeAuth,
                },
                component: () => import("@/views/main/merchants/Pending.vue"),
              },
              {
                path: "/tenants/rejected-tenants",
                name: "Rejected Tenants",
                meta: {
                  auth: Constants.backOfficeAuth,
                },
                component: () => import("@/views/main/merchants/Reject.vue"),
              },
            ],
          },
          {
            path: "/tenants/tenant/:merchantId/:tab",

            name: "Tenants Compliance",
            component: () =>
              import(
                "@/views/main/merchants/merchantDetails/MerchantDetails.vue"
              ),
          },
        ],
        meta: {
          title: "Tenants - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Tenants in Katsu account",
            },
            {
              property: "og:description",
              content: "Tenants in Katsu account",
            },
          ],
        },
      },
      {
        path: "/account_settings",
        name: "Account Settings",
        redirect: "/profile_settings",
        component: () => import("@/views/settings/Settings.vue"),
        children: [
          {
            path: "/profile_settings",
            name: "Profile Settings",
            component: () => import("@/views/settings/ProfileSettings.vue"),
          },
          {
            path: "/settings",
            name: "tax settings",

            component: () => import("@/views/settings/SettingIndex.vue"),
          },
          {
            path: "/security",
            name: "Security Settings",
            component: () => import("@/views/settings/Security.vue"),
          },

          {
            path: "/api_keys",
            name: "API Keys Settings",
            component: () => import("@/views/settings/APIKeys.vue"),
          },
          {
            path: "/passcode_settings",
            name: "Passcode Settings",

            component: () => import("@/views/settings/passcode/Passcode.vue"),
          },
          {
            path: "/rates_settings",
            name: "Rates Settings",

            component: () => import("@/views/settings/ConfigureRates.vue"),
          },
        ],
        meta: {
          title: "Account Settings - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Account Settings in Katsu account",
            },
            {
              property: "og:description",
              content: "Account Settings in Katsu account",
            },
          ],
        },
      },
      {
        path: "/pricing",
        name: "Pricing",
        redirect: "/product-pricing",
        component: () => import("@/views/pricing/Pricings.vue"),
        children: [
          {
            path: "/product-pricing",
            name: "Product pricing",
            component: () => import("@/views/pricing/ProductPricing.vue"),
          },
          {
            path: "/verification-pricing",
            name: "Verification pricing",

            component: () => import("@/views/pricing/VerificationPricing.vue"),
          },
          {
            path: "/client-pricing",
            name: "Client pricing",
            component: () => import("@/views/pricing/clients/clients.vue"),
          },
          {
            path: "/client-pricing/:id",
            name: "Customer Client pricing",
            component: () => import("@/views/pricing/clients/client.vue"),
          },
        ],
        meta: {
          title: "Pricings - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Pricings in Katsu account",
            },
            {
              property: "og:description",
              content: "Pricings in Katsu account",
            },
          ],
        },
      },
      {
        path: "/bank-transfers",
        name: "bank transfers",

        component: () =>
          import("@/views/main/banktransfers/IndexComponent.vue"),
        children: [
          {
            path: "",
            name: "bank transfer List",
            component: () =>
              import("@/views/main/banktransfers/TransferList.vue"),
          },
          {
            path: ":name",
            name: "bank Transfer",
            component: () =>
              import("@/views/main/banktransfers/TransferIndex.vue"),
          },
        ],
        meta: {
          title: "Bank transfers - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Bank transfers in Katsu account",
            },
            {
              property: "og:description",
              content: "Bnak transfers in Katsu account",
            },
          ],
        },
      },
      {
        path: "/accounts",
        name: "accounts",

        component: () => import("@/views/main/accounts/IndexComponent.vue"),

        meta: {
          title: "Account list - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Account list in Katsu account",
            },
            {
              property: "og:description",
              content: "Account list in Katsu account",
            },
          ],
        },
      },
      {
        path: "/customers",
        name: "customers",

        component: () => import("@/views/main/customers/IndexComponent.vue"),
      },
      {
        path: "/virtual-accounts",
        name: "virtual",

        component: () =>
          import("@/views/main/virtualaccounts/IndexComponent.vue"),
        children: [
          {
            path: "",
            name: "virtual",
            component: () =>
              import("@/views/main/virtualaccounts/AccountsList.vue"),
          },
          {
            path: "new",
            name: "new virtual",
            component: () =>
              import("@/views/main/virtualaccounts/AccountIndex.vue"),
          },
          {
            path: "list",
            name: "virtual List",
            component: () =>
              import("@/views/main/virtualaccounts/AccountList.vue"),
          },
        ],
        meta: {
          title: "Virtual accounts - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Virtual accounts in Katsu account",
            },
            {
              property: "og:description",
              content: "Virtual accounts in Katsu account",
            },
          ],
        },
      },
      {
        path: "/ameta-id",
        name: "Katsu ID",

        component: () => import("@/views/main/ametaid/IndexComponent.vue"),

        meta: {
          title: "Katsu ID - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Katsu ID in Katsu account",
            },
            {
              property: "og:description",
              content: "Katsu ID in Katsu account",
            },
          ],
        },
      },
      {
        path: "/ameta-id/tenant/:tenantId",
        name: "Katsu ID Tenant",

        component: () => import("@/views/main/ametaid/TenantView.vue"),

        meta: {
          title: "Katsu ID Tenant - Katsu",
          metaTags: [
            {
              name: "description",
              content: "Katsu ID Tenant in Katsu account",
            },
            {
              property: "og:description",
              content: "Katsu ID Tenant in Katsu account",
            },
          ],
        },
      },
    ],
  },

  //ends here
  {
    path: "/:catchAll(.*)",
    name: "Not found",
    title: "Page not found - Katsu",
    metaTags: [
      {
        name: "description",
        content: "Page not found on Katsu",
      },
      {
        property: "og:description",
        content: "Page not found on Katsu",
      },
    ],
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound"),
  },
];
