<template>
  <div class="h-full">
    <div class="flex flex-col">
      <div class="mb-28"><SuprBizLogo /></div>
      <div class="flex flex-col pb-28">
        <!-- <div class="flex"> -->
        <!-- {{ process }} -->
        <WelcomeBack />
        <!-- </div> -->
        <p class="grey-5 fs-18 fw-400 mt-8 mb-10">Login to your account</p>
        <FormErrorAlert
          @clicked="hideErrorAlert"
          v-if="showErrorAlert"
          :msg="errMsg"
        />
        <Form
          @submit="handleLogin"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="flex flex-col"
        >
          <div class="mb-4">
            <div class="relative">
              <Field
                id="Email"
                name="email"
                type="text"
                v-model="userEmail"
                autocomplete="off"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.email }"
              />

              <span class="floating-label z-20">Email Address</span>
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.email }}</div>
          </div>

          <div class="mb-6">
            <div class="relative">
              <Field
                id="Password"
                name="password"
                type="password"
                autocomplete="off"
                v-model="userPassword"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.password }"
              />

              <span class="floating-label">Password</span>
              <span class="showPasswordBtn cursor-pointer">
                <div
                  class="px-3 cursor-pointer"
                  v-if="!showPassword"
                  @click="show('Password')"
                >
                  Show
                </div>
                <div
                  class="px-3 cursor-pointer"
                  v-else
                  @click="hide('Password')"
                >
                  Hide
                </div>
              </span>
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.password }}</div>
          </div>

          <div class="mb-6">
            <span
              @click="goToPasswordReset"
              class="blue-7 fw-500 fs-14 cursor-pointer"
            >
              Forgot Password
            </span>
          </div>

          <button
            :disabled="userEmail.length === 0 || userPassword.length === 0"
            :class="
              Object.keys(errors).length > 0 ||
              userEmail.length === 0 ||
              userPassword.length === 0
                ? 'opacity-25'
                : 'opacity-100'
            "
            class="h-52px text-white fs-16 fw-500 bluebtn br-8 flex items-center justify-center"
          >
            Login

            <div v-if="loading" class="h-4 w-4 ml-4 rounded-md block">
              <div class="roundLoader opacity-50 mx-auto"></div>
            </div>
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import SuprBizLogo from "@/components/svg/SuprBizLogo.vue";
import WelcomeBack from "@/components/svg/WelcomeBack.vue";
import FormErrorAlert from "@/components/FormErrorAlert.vue";
import { useToast } from "vue-toast-notification";
import { reactive, toRefs, ref, onMounted } from "vue";
// import UserInfo from "@/services/userInfo/userInfo.js";

import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import LoginService from "@/services/login/LoginService.js";
import { Log } from "@/components/util";
// import UserActions from "@/services/userActions/userActions.js";
// import { useStore } from "vuex";
// import { askForPermissioToReceiveNotifications } from "@/push-notification";

export default {
  name: "Login",
  components: {
    SuprBizLogo,
    WelcomeBack,
    FormErrorAlert,
    Form,
    Field,
  },
  setup() {
    onMounted(() => {
      // alert(process.env.VUE_APP_BASE_URL);
    });
    const toast = useToast();
    const router = useRouter();
    // const store = useStore();
    const loading = ref(false);
    const errMsg = ref("");
    const showErrorAlert = ref(false);
    const showPassword = ref(false);

    const user = reactive({
      userEmail: "",
      userPassword: "",
    });

    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string().required("Password is required"),
    });

    const hide = (id) => {
      showPassword.value = false;
      document.getElementById(id).type = "password";
    };

    const show = (id) => {
      showPassword.value = true;
      document.getElementById(id).type = "text";
    };

    // const postDeviceInfo = () => {
    //   const device = Util.getDeviceType(window.navigator.userAgent);
    //   const os = Util.getSystemOs(window.navigator.userAgent);
    //   Log.info("type:" + device);
    //   Log.info("os:" + os);

    //   Log.info("info nav:" + JSON.stringify(window.navigator));
    //   UserActions.postDeviceInfo(
    //     {
    //       deviceName: device,
    //       operatingSystem: os,
    //     },
    //     (response) => {
    //       Log.info(response);
    //     },
    //     (error) => {
    //       Log.error(error);
    //     }
    //   );
    // };

    const hideErrorAlert = () => {
      showErrorAlert.value = false;
    };

    const handleLogin = (values) => {
      loading.value = true;
      Log.info(loading.value);
      Log.info("user:" + JSON.stringify(user));
      LoginService.loginUser(
        {
          email: values.email,
          password: values.password,
        },
        (response) => {
          loading.value = false;
          console.log("Login", response.data.data);
          const data = response.data.data;
          if (
            data.roles.includes("SuperAdmin") ||
            data.roles.includes("Admin")
          ) {
            LoginService.handleSuccessfulLogin(response, "/dashboard");
            toast.success("You are logged in", {
              position: "top-right",
            });
          } else {
            toast.error("You are not authorised", {
              position: "top-right",
            });
          }
          // router.push("/dashboard");
        },
        (error) => {
          Log.error("login error:" + JSON.stringify(error));
          // Log.info("it erred")
          loading.value = false;

          // if (Util.checkIfServerError(error.response.status)) {
          // 	Log.error(" error status:" + JSON.stringify(error.response.status));

          // 	return;
          // }

          // Util.handleGlobalAlert(true, "failed", error.response.data.Message);
          errMsg.value = error.response.data.Message;
          showErrorAlert.value = true;

          // Util.throttle({
          // 	key: "login",
          // 	run: () => {
          // 		Log.info("re do login");
          // handleLogin();
          // 	},
          // 	time: 400,
          // });
          // handleLogin();
        }
      );
    };

    const goToSignUp = () => {
      router.push("/signup");
    };

    const goToPasswordReset = () => {
      router.push("/reset_password");
    };

    return {
      ...toRefs(user),
      handleLogin,
      goToSignUp,
      schema,
      goToPasswordReset,
      loading,
      errMsg,
      showErrorAlert,
      hideErrorAlert,
      hide,
      show,
      showPassword,
      // process,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/inputs.scss";
</style>
