<template>
  <div class="h-screen overflow-y-auto bg-white">
    <div class="flex overflow-y-auto min-h-screen">
      <div
        class="py-10 px-6 sm:px-20 w-full md:w-[37rem] mx-auto lg:w-[40rem] onboarding overflow-y-auto"
      >
        <router-view></router-view>
      </div>

      <CarouselSection class="hidden md:flex" />
    </div>
  </div>
</template>

<script>
import CarouselSection from "@/views/onboarding/CarouselSection.vue";
export default {
  name: "Home",
  components: {
    CarouselSection,
    // HelloWorld,
  },
};
</script>
